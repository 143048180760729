import React from 'react';

import { StoryblokBase, StoryblokComponentProps } from '../../@types/storyblok';
import RichTextResolver from './RichTextResolver/RichTextResolver';

interface RichTextProps extends StoryblokBase {
    richText: any
}

const RichText = (props: StoryblokComponentProps<RichTextProps>) => {

    const resolver = new RichTextResolver();

    return (
        <div className="m-2 bg-white p-3 rich-text" dangerouslySetInnerHTML={{ __html: resolver.render(props.blok.richText) }} />
    )
}

export default RichText;