import React, { createRef, forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import { StoryblokBase, StoryblokComponentProps } from '../../../@types/storyblok';
import { CollectionData } from './collection-modal/CollectionModal';
import { timeRange } from '../../TimeRangePicker';
import { CollectionConfig } from './CollectionConfig';

const groupBy = function (xs: any, key: string) {
    return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

interface DatePickerStepProps {
    setDate: any;
    date: any;
}

const DatePickerStep = (props: DatePickerStepProps) => {
    const { setDate, date } = props;

    const ref = createRef();
    const DatepickerInput = forwardRef(({ ...props }, ref: any) => (
        <input type="text" {...props} readOnly ref={ref} />
    ));
    return (
        <div className="flex flex-col">
            <h5 className="mt-4">Bitte einen Tag auswählen</h5>
            <DatePicker
                dateFormat="dd-MM-yyyy"
                className="border-2 p-2 text-center w-full border-grey mt-2 mb-4"
                selected={date}
                onChange={(value: any) => setDate(value)}
                minDate={new Date(CollectionConfig.start)}
                maxDate={new Date(CollectionConfig.end)}
                placeholderText={"Tag auswählen"}
                customInput={<DatepickerInput ref={ref} />}
            />
        </div>
    )
}

interface TableProps {
    data: CollectionData[]
}
const Table = (props: TableProps) => {
    const { data } = props;

    // group via locationID
    const groupedData: { [key: string]: CollectionData[] } = groupBy(data, 'locationId');

    // create accessible array
    const collectionPlaces: [CollectionData[]] = [[]];
    for (const [key, value] of Object.entries(groupedData)) {
        collectionPlaces.push(value);
    }

    // remove empty entries
    const places = collectionPlaces.filter((arr) => arr.length >= 1)

    // sort by time
    const sortedArray = places.map(arr => arr.sort((a, b) => parseInt(a.time!) - parseInt(b.time!)))

    return (
        sortedArray.length >= 1 ?
            <div className="bg-white m-2 p-3">
                {<h1 className="capitalize mb-6">{sortedArray[0][0].city}</h1>}
                {sortedArray.map((item, index) =>
                    <div key={"location-" + sortedArray[0][0].city + "-" + index}>
                        <h3 className="my-4">{item[0].locationName}</h3>
                        <table className="border-collapse border-2 border-blue-light-900 table-fixed w-full">
                            <thead className="bg-blue-light-100">
                                <tr>
                                    <th className="w-1/4 text-left border border-blue-light-100">
                                        Name
                            </th>
                                    <th className="w-1/4 text-left border border-blue-light-100">
                                        Email
                            </th>
                                    <th className="w-1/4 text-left border border-blue-light-100">
                                        Verein
                            </th>
                                    <th className="w-1/4 text-left border border-blue-light-100">
                                        Uhrzeit
                            </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    item.map((value, index) =>
                                        <tr key={value.locationId + "-" + index}>
                                            <td className="border border-blue-light-100">{value.name}</td>
                                            <td className="border border-blue-light-100 break-all">{value.email}</td>
                                            <td className="border border-blue-light-100 break-all">{value.club}</td>
                                            <td className="border border-blue-light-100">{timeRange[value.time!]}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                )}
            </div> : <p></p>
    )
}

interface RegisteredDataTableProps extends StoryblokBase {

}

const RegisteredDataTable = (props: StoryblokComponentProps<RegisteredDataTableProps>) => {
    const [data, setData] = useState<CollectionData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [date, setDate] = useState<Date | null>(null);

    useEffect(() => {
        if (date) {
            const getData = async () => {
                setIsLoading(true)
                const response = await fetch(`/.netlify/functions/get-all-data?date=${date.toDateString()}&pwd=Wdg83ksuZ`);
                const responseData = await response.text()
                setData(JSON.parse(responseData))
                setIsLoading(false);
            }
            getData();
        }
    }, [date])

    const wadgassen: CollectionData[] = data.filter((x) => x.city === "wadgassen");
    const hostenbach: CollectionData[] = data.filter((x) => x.city === "hostenbach");
    const schaffhausen: CollectionData[] = data.filter((x) => x.city === "schaffhausen");
    const werbeln: CollectionData[] = data.filter((x) => x.city === "werbeln");
    const differten: CollectionData[] = data.filter((x) => x.city === "differten");
    const friedrichweiler: CollectionData[] = data.filter((x) => x.city === "friedrichweiler");

    const cities = [wadgassen, hostenbach, schaffhausen, werbeln, differten, friedrichweiler]

    return (
        <div>
            <DatePickerStep setDate={setDate} date={date} />
            <div>
                {
                    date &&
                    <div>
                        <h1 className="p-3">{date.toLocaleDateString("de")}</h1>
                        {
                            cities.map((city, index) => <Table key={"city-" + index} data={city} />)
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default RegisteredDataTable