import React from 'react';
import { Helmet } from 'react-helmet';

import { StoryblokBase, StoryblokComponentProps, StoryblokImage, StoryblokMetaFields } from '../../../@types/storyblok';
import BackHeader from '../../BackHeader';
import { CategoryType } from '../CategoryCard';
import LinkList from '../LinkList';
import RichTextResolver from '../RichTextResolver/RichTextResolver';
import MainSlider from '../Slider';
import Garbage from '../../../images/icons/garbage.svg'

export interface SpringCleaningPageProps extends StoryblokBase {
    title: string,
    slider: StoryblokImage[],
    description: any,
    locations: string[],
    seo: StoryblokMetaFields,
}

const SpringCleaningPage = (props: StoryblokComponentProps<SpringCleaningPageProps>) => {
    const { blok } = props;
    const { seo } = blok;

    const resolver = new RichTextResolver();

    const isBrowser = typeof window !== "undefined"

    return (
        isBrowser &&
        <div>
            <Helmet>
                {seo?.title && <title>{seo.title}</title>}
                {seo?.description && <meta name="description" content={seo.description} />}

                <meta property="og:url" content={window?.location.href} />
                <meta property="og:type" content="website" />
                {seo?.title && <meta property="og:title" content={seo.title} />}
                {seo?.description && <meta property="og:description" content={seo.description} />}
            </Helmet>
            <BackHeader lang={props.lang} type={CategoryType.Action} />
            <div className="bg-grey">
                <div className="bg-white m-2">
                    <div className="flex flex-row items-center p-3">
                        <Garbage />
                        <div className="flex flex-col pl-4">
                            <h1 className="font-bold leading-none">{blok.title}</h1>
                        </div>
                    </div>
                    <MainSlider slides={blok.slider} />
                    <div className="p-3">
                        {
                            blok.description &&
                            <div dangerouslySetInnerHTML={{ __html: resolver.render(blok.description) }} />
                        }
                        <LinkList list={blok.locations} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpringCleaningPage