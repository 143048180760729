import React from 'react';

import { StoryblokComponentProps } from '../../@types/storyblok';
import { RestaurantCard, RestaurantCardItem } from './RestaurantSummary';


const RestaurantPage = (props: StoryblokComponentProps<RestaurantCardItem>) => {

    return (
        <RestaurantCard card={props.blok} lang={props.lang} />
    )
}

export default RestaurantPage;