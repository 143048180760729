import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import HikeIcon from './../../images/icons/hike.svg';
import FreeTimeIcon from './../../images/icons/freetime.svg';
import RestaurantIcon from './../../images/icons/gastro.svg';
import EventIcon from './../../images/icons/event.svg';

import { StoryblokBase, StoryblokComponentProps, StoryblokLink } from '../../@types/storyblok';

export enum CategoryType {
    Hike = "Hike",
    FreeTime = "FreeTime",
    Restaurant = "Restaurant",
    Event = "Event",
    Action = "Action",
    News = "News"
}

interface Category {
    Type: CategoryType,
    Icon: string,
    Color: {
        Light: string,
        Dark: string
    },
    Image: string,
}

export const Categories: Category[] = [
    {
        Type: CategoryType.Hike,
        Icon: HikeIcon,
        Color: {
            Light: 'bg-green-100',
            Dark: 'bg-green-900'
        },
        Image: 'HikeBg'
    },
    {
        Type: CategoryType.FreeTime,
        Icon: FreeTimeIcon,
        Color: {
            Light: 'bg-blue-light-100',
            Dark: 'bg-blue-light-900'
        },
        Image: 'FreeTimeBg'
    },
    {
        Type: CategoryType.Restaurant,
        Icon: RestaurantIcon,
        Color: {
            Light: 'bg-blue-dark-100',
            Dark: 'bg-blue-dark-900'
        },
        Image: 'GastroBg'
    },
    {
        Type: CategoryType.Event,
        Icon: EventIcon,
        Color: {
            Light: 'bg-red-100',
            Dark: 'bg-red-900'
        },
        Image: 'EventBg'
    },
    {
        Type: CategoryType.Action,
        Icon: EventIcon,
        Color: {
            Light: 'bg-orange-100',
            Dark: 'bg-orange-900'
        },
        Image: 'EventBg'
    },
    {
        Type: CategoryType.News,
        Icon: EventIcon,
        Color: {
            Light: 'bg-blue-medium-100',
            Dark: 'bg-blue-medium-900'
        },
        Image: 'EventBg'
    }
]

interface CategoryCardProps extends StoryblokBase {
    type: string,
    title: string,
    link: StoryblokLink
}

export const categoryCardImage = graphql`fragment categoryCardImage on File {
    childImageSharp {
        gatsbyImageData(quality: 70, height: 130)
    }
}`;

const CategoryCard = (props: StoryblokComponentProps<CategoryCardProps>) => {
    const { blok } = props;
    const card = Categories.find((x) => x.Type === CategoryType[blok.type as keyof typeof CategoryType]);
    const data = useStaticQuery(graphql`
        query {
            HikeBg: file(relativePath: { eq: "images/hikebg.jpg" }) {
                ...categoryCardImage
            }
            FreeTimeBg: file(relativePath: { eq: "images/freetimebg.jpg" }) {
                ...categoryCardImage
            }
            EventBg: file(relativePath: { eq: "images/eventbg.jpg" }) {
                ...categoryCardImage
            }
            GastroBg: file(relativePath: { eq: "images/gastrobg.jpg" }) {
                ...categoryCardImage
            }
        }
    `)

    return card ?
        <Link to={blok.link?.cached_url ? blok.link.cached_url : '/'} className={'grid grid-cols-3 mb-5 ' + card.Color.Dark}>
            <div className="flex flex-col justify-center items-center">
                <card.Icon />
                <div className="text-white uppercase font-extrabold text-xs pt-3">{blok.title}</div>
            </div>
            <div className="col-span-2 h-28">
                <GatsbyImage
                    image={data[card.Image].childImageSharp.gatsbyImageData}
                    className="object-cover h-28 w-full"
                    alt={blok.title} />
            </div>
        </Link> :
        <div>Displaying of the card was not possible. Please check the data of the card.</div>;
}

export default CategoryCard
