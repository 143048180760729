import React from "react";

import {
  StoryblokBase,
  StoryblokComponentProps,
  StoryblokImage,
  StoryblokLink,
  StoryblokMetaFields,
} from "../../@types/storyblok";
import BackHeader from "./../BackHeader";
import { CategoryType } from "./CategoryCard";
import MainSlider from "./Slider";
import RichTextResolver from "./RichTextResolver/RichTextResolver";
import Translations from "../../translation/translations.json";
import ExternalLink from "../ExternalLink";
import { Helmet } from "react-helmet";

export interface FreetimePageProps extends StoryblokBase {
  title: string;
  logo: StoryblokImage;
  slider: StoryblokImage[];
  previewImagePosition: string;
  adress: string;
  openingHours: string;
  entranceFee: string;
  text: any;
  seo: StoryblokMetaFields;
  facebook: string;
  instagram: string;
  youtubeEmbed: string;
  routeUrl: StoryblokLink;
}

const FreetimePage = (props: StoryblokComponentProps<FreetimePageProps>) => {
  const { blok, lang } = props;
  const { seo } = blok;
  const resolver = new RichTextResolver();
  const html = resolver.render(blok.text);

  const isBrowser = typeof window !== "undefined";
  console.log(blok);
  return (
    isBrowser && (
      <div>
        <Helmet>
          {seo?.title && <title>{seo.title}</title>}
          {seo?.description && (
            <meta name="description" content={seo.description} />
          )}

          <meta property="og:url" content={window?.location.href} />
          <meta property="og:type" content="website" />
          {seo?.title && <meta property="og:title" content={seo.title} />}
          {seo?.description && (
            <meta property="og:description" content={seo.description} />
          )}
          {blok.slider && (
            <meta property="og:image" content={blok.slider[0].filename} />
          )}
        </Helmet>
        <BackHeader lang={props.lang} type={CategoryType.FreeTime} />
        <div className="bg-grey">
          <div className="bg-white m-2">
            <div className="flex flex-row items-center p-3">
              {blok.logo && (
                <img
                  className="h-12"
                  src={blok.logo.filename}
                  alt={blok.logo.alt}
                />
              )}
              <div className="flex flex-col pl-4">
                <h1 className="font-bold leading-none">{blok.title}</h1>
                <p className="text-xs">{blok.adress}</p>
              </div>
            </div>
            <MainSlider slides={blok.slider} size={"800x0"} />
            <div className="grid grid-cols-2 p-3 text-xs">
              {blok.openingHours && (
                <div>
                  <p className="font-extrabold">
                    {Translations.openingHours[lang]}
                  </p>
                  <p className="whitespace-pre">{blok.openingHours}</p>
                </div>
              )}
              {blok.entranceFee && (
                <div>
                  <p className="font-extrabold">
                    {Translations.entranceFee[lang]}
                  </p>
                  <p className="whitespace-pre">{blok.entranceFee}</p>
                </div>
              )}
            </div>
            <div
              className="p-3 whitespace-pre-wrap rich-text"
              dangerouslySetInnerHTML={{
                __html: html ? html : "<p>No Data</p>",
              }}
            ></div>
            {blok.youtubeEmbed && (
              <div className="p-3">
                <div
                  style={{
                    position: "relative",
                    height: "0",
                    paddingBottom: "56.25%",
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    src={`https://www.youtube.com/embed/${blok.youtubeEmbed}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            )}
            {blok.routeUrl.cached_url && (
              <div className="p-3">
                <ExternalLink url={blok.routeUrl.cached_url}>
                  route
                </ExternalLink>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default FreetimePage;
