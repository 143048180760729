import React from 'react';

import BackIcon from './../images/icons/back.svg';
import { Categories, CategoryType } from './storyblok-components/CategoryCard';
import Translations from '../translation/translations.json'


interface BackHeaderProps {
    type: CategoryType,
    lang?: string
}

const BackHeader = (props: BackHeaderProps) => {

    const category = Categories.find((x) => x.Type === props.type);

    const title = Translations[props.type.toLowerCase()][props.lang];

    return (
        <div className={"flex " + category?.Color.Dark}>
            <div className="flex-1">
                <button aria-label="Back" onClick={() => history.back()} className={"flex h-10 w-10 flex-wrap justify-center content-center " + category?.Color.Light}>
                    <BackIcon />
                </button>
            </div>
            <div className="flex-1 flex flex-wrap justify-center content-center">
                <p className="uppercase font-extrabold text-white">{title ? title : 'Please check your translations in data source'}</p>
            </div>
            <div className="flex-1"></div>
        </div>
    )
}

export default BackHeader