import React, { useState } from 'react';

import Ehrlich from './../../images/icons/ehrlich.svg'
import Offen from './../../images/icons/offen.svg'
import Herzlich from './../../images/icons/herzlich.svg'
import { StoryblokBase, StoryblokComponentProps } from '../../@types/storyblok';

interface FeatureCardProps extends StoryblokBase {
    text1: string,
    text2: string,
    text3: string
}

const FeatureCard = (props: StoryblokComponentProps<FeatureCardProps>) => {
    const { blok } = props;
    const [slideIndex, setSlideIndex] = useState(0);

    React.useEffect(() => {
        const startSlider = () => {
            if (slideIndex >= 2) {
                setSlideIndex(s => 0);
            } else {
                setSlideIndex(s => s + 1);
            }
        }

        const interval = setInterval(startSlider, 4000);
        return () => clearInterval(interval);
    }, [slideIndex]);

    return (
        <div className="grid grid-cols-3 bg-blue-dark-900 py-8">
            <div className="place-self-center">
                <Herzlich className={slideIndex === 0 ? 'h-20 p-2 transition-opacity duration-700 ease-in-out' : 'opacity-0 hidden'} alt={"Wadgassen " + blok.text1} />
                <Ehrlich className={slideIndex === 1 ? 'h-20 p-2 transition-opacity duration-700 ease-in-out' : 'opacity-0 hidden'} alt={"Wadgassen " + blok.text2} />
                <Offen className={slideIndex === 2 ? 'h-20 p-2 transition-opacity duration-700 ease-in-out' : 'opacity-0 hidden'} alt={"Wadgassen " + blok.text3} />
            </div>
            <div className="col-span-2 self-center">
                <h1 className="text-blue-light-900 font-bold text-3xl">
                    <span className={slideIndex === 0 ? 'text-white transition duration-500 ease-in-out' : ''}>{blok.text1} </span>
                    <span className={slideIndex === 1 ? 'text-white transition duration-500 ease-in-out' : ''}>{blok.text2} </span>
                    <span className={slideIndex === 2 ? 'text-white transition duration-500 ease-in-out' : ''}>{blok.text3}</span>
                </h1>
            </div>
        </div>
    )
}

export default FeatureCard