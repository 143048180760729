import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel'

import { StoryblokBase, StoryblokComponentProps, StoryblokImage, StoryblokLink } from '../../@types/storyblok';
import { News } from './NewsSummary';
import RichTextResolver from './RichTextResolver/RichTextResolver';

import './NewsCard.css'

interface Action {
    icon: StoryblokImage,
    title: string,
    link: StoryblokLink
}

interface NewsCarouselProps {
    news: News[],
    lang: string
}

const NewsCarousel = (props: NewsCarouselProps) => {
    const { news, lang } = props;

    const resolver = new RichTextResolver();
    return (
        news ?
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                totalSlides={news.length}
                isIntrinsicHeight={true}
                dragEnabled={false}
                interval={6000}
                isPlaying={true}
                className="relative"
            >
                <Slider aria-label="options" >
                    {news.map((slide, index) =>
                        <Slide aria-label="slide" key={slide._uid} index={index}>
                            <p className="text-xs font-semibold">{slide.title}
                                <span className="font-light float-right pl-3">
                                    {new Date(slide.created_at).toLocaleDateString(lang == "default" ? "de" : lang)}
                                </span>
                            </p>
                            <div className="text-xs rich-text truncate" dangerouslySetInnerHTML={{ __html: resolver.render(slide.text ? slide.text : "") }} />
                        </Slide>
                    )}
                </Slider>
                <DotGroup />
            </CarouselProvider> : <div>Noch keine News verfügbar</div>
    )
}

interface NewsCardProps extends StoryblokBase {
    action: Action[]
}

const NewsCard = (props: StoryblokComponentProps<NewsCardProps>) => {
    const { blok, lang } = props;
    const action = blok.action.length >= 0 && blok.action[0];

    const [news, setNews] = useState<News[]>([]);

    const data = useStaticQuery(graphql`
    query {
        allStoryblokEntry(filter: {field_component: {eq: "news"}}) {
            edges {
              node {
                id
                uuid
                name
                full_slug
                field_component
                content
                lang
                slug
                created_at
              }
            }
        }
    }`)

    useEffect(() => {

        const items: News[] = data.allStoryblokEntry.edges.filter((x: any) => x.node.lang === lang).map((item: any) => {
            let newsItem: News;
            newsItem = { ...JSON.parse(item.node.content) };
            newsItem.created_at = item.node.created_at;
            return newsItem;
        });

        setNews(items.slice(0, 4));

    }, [data])

    return (
        <div className="overflow-hidden">
            <div className="grid grid-cols-3 h-24">
                <Link className={action ? "col-span-2" : "col-span-full"} to="/news">
                    <div className="h-full bg-blue-medium-900 p-2 overflow-hidden text-xs text-white">
                        <p className="uppercase font-bold text-sm text-white mb-2">News</p>
                        <NewsCarousel news={news} lang={lang} />
                    </div>
                </Link>
                {
                    action &&
                    <Link to={action.link.cached_url}>
                        <div className="col-span-1 h-full bg-orange-900 p-2 flex flex-col items-center text-center">
                            {
                                <p className="uppercase text-white font-bold text-sm mb-2">{action.title}</p>
                            }
                            {
                                <img width="50" height="38" src={action.icon.filename} alt={action.icon.alt} />
                            }
                        </div>
                    </Link>

                }

            </div>
        </div>
    )
}

export default NewsCard