import React from 'react';

import { StoryblokBase, StoryblokComponentProps } from '../../@types/storyblok';

interface SpacingProps extends StoryblokBase {
    height: string;
}
const Spacing = (props: StoryblokComponentProps<SpacingProps>) => {
    const { height } = props.blok;
    return (
        <div className={height ? "h-" + height : "h-0"}>
        </div>
    )
}

export default Spacing;