import React from 'react';
import classNames from 'classnames';

import { StoryblokImage } from '../@types/storyblok';

import Facebook from '../images/icons/facebook.svg'
import Instagram from '../images/icons/instagram.svg'
import { TransformImage } from './TransformImage';

interface BasicCardProps {
    title: string,
    image?: StoryblokImage,
    imgPos: string,
    subTitle?: string,
    facebook?: string,
    instagram?: string,
    date?: string
}

const ObjectPositon = {
    center: "object-center",
    bottom: "object-bottom",
    left: "object-left",
    leftBottom: "object-left-bottom",
    leftTop: "object-left-top",
    right: "object-right",
    rightBottom: "object-right-bottom",
    rightTop: "object-right-top",
    top: "object-center",
}

const BasicCard: React.FC<BasicCardProps> = (props) => {
    const { imgPos } = props;
    let mappedImgPos = ObjectPositon[imgPos];
    if (!mappedImgPos) {
        mappedImgPos = "object-center";
    }
    const cardClasses = classNames("object-cover", mappedImgPos, "h-40 w-full")
    return (
        <div className="bg-white">
            <div className="py-2 px-3 ">
                <p className="font-extrabold">{props.title}</p>
                {
                    props.date &&
                    <p className="text-xs">{props.date}</p>
                }
            </div>
            {
                props.image &&
                <img className={cardClasses} src={TransformImage(props.image.filename, "800x0", "filters:quality(70)")} alt={props.image.alt} />
            }
            {
                props.subTitle &&
                <div className="px-3 pt-4 flex items-center">
                    <p className="flex-auto text-xs">
                        {props.subTitle}
                    </p>
                    <div className="flex flex-1 justify-end">
                        {
                            props.facebook &&
                            <a className="pl-4" aria-label="Facebook" href={props.facebook} target="blank" rel="noreferrer">
                                <Facebook />
                            </a>
                        }
                        {
                            props.instagram &&
                            <a className="pl-4" aria-label="Instagram" href={props.instagram} target="blank" rel="noreferrer">
                                <Instagram />
                            </a>
                        }
                    </div>
                </div>
            }
            {props.children}
        </div>
    )
}

export default BasicCard;