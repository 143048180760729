import React, { useEffect, useState } from 'react';

import Translations from '../translation/translations.json'

interface AudioPlayerProps {
    title: string,
    name: string,
    stationNumber: number,
    fileName: string,
    lang: string,
    startPlay: boolean,
    id: string,
    active: boolean,
    onPlaying: any
}

const AudioPlayer = (props: AudioPlayerProps) => {
    const { name, title, stationNumber, fileName, lang, id, active, onPlaying } = props;
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(active)
    });

    return (
        <div id={id} className={isActive ? "bg-green-100 bg-opacity-60 p-4 transition-all" : "p-4"}>
            <p className="font-extrabold text-base">{Translations.station[lang]} {stationNumber}</p>
            <p className="pb-2 text-sm">{name}</p>
            <audio
                onPlaying={onPlaying}
                className="w-full transparent"
                controls
                crossOrigin="anonymous"
                src={fileName}>
                Your browser does not support the
            <code>audio</code> element.
        </audio>
        </div>
    )
}

export default AudioPlayer;