import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { StoryblokAudio, StoryblokBase, StoryblokComponentProps, StoryblokImage, StoryblokLink, StoryblokMetaFields } from "../../@types/storyblok";
import BackHeader from '../BackHeader';
import { CategoryType } from './CategoryCard';
import RichTextResolver from './RichTextResolver/RichTextResolver';
import Translations from '../../translation/translations.json'
import MainSlider from './Slider';
import { DifficultyType } from '../Difficulty';
import AudioPlayer from '../AudioPlayer';

import Preload from '../../images/icons/preload.svg'
import LauschTour from '../../images/lauschtour.png'
import ExternalLink from '../ExternalLink';

export interface HikePageProps extends StoryblokBase {
    komootUrl: StoryblokLink,
    routeUrl: string,
    slider: StoryblokImage[],
    showLauschTour: boolean,
    text: any,
    audio: StoryblokAudio[],
    title: string,
    duration: number,
    distance: number,
    difficulty: DifficultyType,
    uphill: number,
    downhill: number,
    seo: StoryblokMetaFields
}

const replaceStoryBlokUrl = (url: string) => {
    return url.replace("a.storyblok.com", "s3.amazonaws.com/a.storyblok.com");
}

const HikePage = (props: StoryblokComponentProps<HikePageProps>) => {
    const [activeAudio, setActiveAudio] = useState(0);
    const [isPreloading, setIsPreloading] = useState(false);
    const [isPreloaded, setIsPreloaded] = useState(false);

    const { blok, lang } = props;
    const { seo } = blok;

    const resolver = new RichTextResolver();
    const html = resolver.render(blok.text);

    const hash = props.location?.hash;
    React.useEffect(() => {
        if (hash) {
            const audioNumber = hash.replace("#audio-", "")
            setActiveAudio(Number(audioNumber))
        }
    }, [hash]);

    // on mounting: check if cache is already preloaded
    React.useEffect(() => {
        checkIfAudioIsPreloaded();
    }, [])

    const checkIfAudioIsPreloaded = async () => {
        const hasAudio = await window.caches.has("audio");

        if (hasAudio) {
            let requestsPromise: Promise<boolean | Request>[] = [];
            const cache = await window.caches.open("audio");
            blok.audio.forEach(audio => {
                requestsPromise.push(cache.keys(replaceStoryBlokUrl(audio.filename)).then(value => {
                    return Promise.resolve(value !== null && value !== undefined && value.length !== 0);
                }))
            });

            var results = await Promise.all(requestsPromise);
            const isPreloaded = results.every(res => res)
            setIsPreloaded(isPreloaded)
            setIsPreloading(false)
        }
    }

    const preloadAudioIntoCache = async () => {
        setIsPreloading(true);
        const cache = await window.caches.open("audio");
        let requestsPromise: Promise<void | RequestInfo>[] = [];

        blok.audio.forEach(async (audio) => {
            requestsPromise.push(cache.add(replaceStoryBlokUrl(audio.filename)));
        });

        await Promise.all(requestsPromise);
        checkIfAudioIsPreloaded();
    }

    const deleteAudioFromCache = async () => {
        const hasAudio = await window.caches.has("audio");
        if (hasAudio) {
            await window.caches.delete("audio");
            setIsPreloaded(false)
        }
    }

    const isBrowser = typeof window !== "undefined"

    return (
        isBrowser ?
            <div>
                <Helmet>
                    {seo?.title && <title>{seo.title}</title>}
                    {seo?.description && <meta name="description" content={seo.description} />}

                    <meta property="og:url" content={window ? window.location.href : ""} />
                    <meta property="og:type" content="website" />
                    {seo?.title && <meta property="og:title" content={seo.title} />}
                    {seo?.description && <meta property="og:description" content={seo.description} />}
                    {blok.slider && <meta property="og:image" content={blok.slider[0].filename} />}
                </Helmet>
                <BackHeader lang={props.lang} type={CategoryType.Hike} />
                <div className="bg-grey grid grid-flow-row gap-2 my-2">
                    <div className="bg-white mx-2 pb-2">
                        {blok.komootUrl && <iframe className="border-0" src={blok.komootUrl + "/embed"} style={{ border: 0 }} width="100%" height="500" frameBorder="0" scrolling="no"></iframe>}
                        {blok.showLauschTour &&
                            <div className="p-3 bg-green-100 bg-opacity-70 flex items-center">
                                <div className="">
                                    <svg className="w-12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" fill="#90983F"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" /></svg>
                                </div>
                                <div className="w-full ml-4 text-sm font-bold flex items-center ">
                                    <p className="text-black text-opacity-50">Auch über Lauschtour <br /> verfügbar:</p>
                                    <a className="ml-auto" href="http://www.app.lauschtour.de/" target="_blank">
                                        <img className="w-14 p-0" src={LauschTour} alt="lauschtour" />
                                    </a>
                                </div>
                            </div>
                        }
                        <div className="p-3 whitespace-pre-wrap rich-text" dangerouslySetInnerHTML={{ __html: html ? html : '<p>No Data</p>' }} />
                        {
                            blok.routeUrl &&
                            <ExternalLink className="p-3" url={blok.routeUrl}>Route</ExternalLink>}
                    </div>

                    {blok.audio &&
                        <div className="bg-white mx-2">
                            <div className="flex items-center p-4">
                                <div className="text-lg font-extrabold">{Translations.audioGuide[lang]}</div>
                                <div className="flex flex-1 justify-end">
                                    {
                                        isPreloaded ?
                                            <button
                                                disabled={isPreloading}
                                                className={isPreloading ? "flex items-center p-1 font-semibold animate-pulse" : "flex items-center p-1 font-semibold"}
                                                onClick={deleteAudioFromCache}>
                                                Cache löschen
                                    </button> :
                                            <button
                                                disabled={isPreloading}
                                                className={isPreloading ? "flex items-center p-1 font-semibold animate-pulse" : "flex items-center p-1 font-semibold"}
                                                onClick={preloadAudioIntoCache}>
                                                {
                                                    isPreloading ? <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1"></circle>
                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg> : <Preload className="mr-2" />
                                                }
                                                 Preload
                                            </button>
                                    }
                                </div>
                            </div>
                            {blok.audio.map((audio, index) =>
                                <AudioPlayer onPlaying={() => setActiveAudio(index + 1)} active={activeAudio === (index + 1)} id={"audio-" + (index + 1)} key={audio.id.toString()}
                                    fileName={replaceStoryBlokUrl(audio.filename)}
                                    lang={lang}
                                    stationNumber={index + 1}
                                    title={audio.title}
                                    name={audio.name}
                                    startPlay={index === 1 ? true : false}
                                />
                            )}
                        </div>
                    }
                    {
                        blok.slider &&
                        <div className="bg-white mx-2">
                            <p className="font-extrabold p-4">{Translations.images[lang]}</p>
                            <MainSlider slides={blok.slider} />
                        </div>
                    }
                </div>
            </div>
            :
            <div></div>

    )
}

export default HikePage;