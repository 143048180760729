export const TransformImage = (source: string, targetSize?: string, filters?: string) => {
    const imageService = "//img2.storyblok.com/";
    const path = source?.replace("http:", "").replace("https:", "").replace("//a.storyblok.com", "").replace("//img2.storyblok.com", "");

    let transformUrl = imageService;
    if (targetSize) {
        transformUrl += targetSize;
    }

    if (filters) {
        filters = transformUrl.endsWith("/") ? filters : "/" + filters;
        transformUrl += filters;
    }

    return transformUrl + path;
};