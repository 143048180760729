import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { StoryblokBase, StoryblokComponentProps } from '../../@types/storyblok';

const BuildId = (props: StoryblokComponentProps<StoryblokBase>) => {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    buildId
                }
            }
        }
    `)

    return (
        <div className="flex-auto text-center p-4">
            <p className="font-light text-black opacity-40">
                {data.site.siteMetadata.buildId}
            </p>
        </div>
    )
}

export default BuildId