import React, { useState } from 'react';

import { StoryblokComponentProps } from "../../../@types/storyblok"
import BackHeader from '../../BackHeader';
import CollectionModal, { CollectionData } from './collection-modal/CollectionModal';
import { CategoryType } from '../CategoryCard';
import { CollectionPoint } from "./CollectionPointSummary"
import RichTextResolver from '../RichTextResolver/RichTextResolver';
import MainSlider from '../Slider';

const paramExtractor = (text: string | undefined) => {
    if (text) {
        const splittedUrl = text.split('/')
        const parent = splittedUrl[splittedUrl.length - 2]
        return parent
    } else {
        return ""
    }
}

const CollectionPointPage = (props: StoryblokComponentProps<CollectionPoint>) => {
    const [isOpen, setIsOpen] = useState(false);
    const { blok } = props;

    const city = paramExtractor(props.location?.pathname);

    const resolver = new RichTextResolver();
    const html = resolver.render(blok.description);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    const collectionData: CollectionData = {
        adress: blok.adress,
        locationId: blok._uid,
        city,
        locationName: blok.title,
        dataPrivacy: false,
        name: "",
        club: "",
        date: "",
        email: "",
        time: ""
    }

    return (
        <div>
            <BackHeader lang={props.lang} type={CategoryType.Action} />
            <div className="bg-grey">
                <div className="bg-white m-2">
                    <div className="flex flex-row items-center p-3">
                        <div className="flex flex-col">
                            <h1 className="font-bold leading-none">{blok.title}</h1>
                            <p className="text-xs first-letter-uppercase">{city}, {blok.adress}</p>
                        </div>
                    </div>
                    <MainSlider slides={blok.slider} />
                    <div className="grid grid-cols-2 p-3 text-xs">
                        {
                            blok.childFriendly &&
                            <div>
                                <p className="font-extrabold">Kindergeeignet</p>
                                <p className="whitespace-pre">
                                    {blok.childFriendly ? "Ja" : "Nein"}
                                </p>
                            </div>
                        }
                    </div>
                    <div className="p-3 whitespace-pre-wrap rich-text" dangerouslySetInnerHTML={{ __html: html ? html : '<p>No Data</p>' }} />
                    <div className="flex justify-center">
                        <button className="p-3 my-8 bg-orange-900 text-white font-extrabold" onClick={toggleModal}>Mitmachen</button>
                        <div className="pb-6"></div>
                    </div>

                    <CollectionModal isOpen={isOpen} toggleModal={toggleModal} data={collectionData} />
                </div>
            </div>
        </div>
    )
}

export default CollectionPointPage