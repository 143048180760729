import React from 'react';

import Translations from '../translation/translations.json'

export enum DifficultyType {
    Easy = "easy",
    Intermediate = "intermediate",
    Expert = "expert",
}

interface DifficultyProps {
    type: DifficultyType,
    lang: string
}

const Difficulty = (props: DifficultyProps) => {
    let bg = 'white';
    switch (props.type) {
        case DifficultyType.Easy:
            bg = 'bg-green-900'
            break;

        case DifficultyType.Intermediate:
            bg = 'bg-red-900'
            break;

        case DifficultyType.Expert:
            bg = 'bg-black'
            break;

        default:
            break;
    }

    return (
        <div className={bg + ' ' + "text-xs text-white px-2 rounded inline-block"}>
            {Translations[props.type][props.lang]}
        </div>
    )
}

export default Difficulty