import { CalendarEvent, google, ics, outlook } from 'calendar-link';
import React, { useEffect, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';

import { Capitalize, ValidateEmail } from '../../../../helper/utils';
import { timeRange } from '../../../TimeRangePicker';

import { CollectionData } from './CollectionModal';


interface SetUserDataProps extends Partial<StepWizardChildProps> {
    data: CollectionData
    setData: (data: CollectionData) => void;
}


const SetUserData = (props: SetUserDataProps) => {
    const { data } = props;
    const [formData, setFormData] = useState<CollectionData | null>(null);
    const [nextStepDisabled, setNextStepDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setFormData({ ...data, dataPrivacy: false });
    }, [data])

    useEffect(() => {
        if (formData?.name && ValidateEmail(formData.email) && formData.dataPrivacy) {
            setNextStepDisabled(false)
        } else {
            setNextStepDisabled(true)
            setFormData(formData);
        }
    }, [formData])

    const nextStep = async () => {
        if (formData) {
            setIsLoading(true)

            const time = timeRange[formData.time!];
            let splittedTime: string[] = []
            if (time) {
                splittedTime = time.split(" - ");
            }

            const start = new Date(formData.date + ' ' + splittedTime[0])
            const end = new Date(formData.date + ' ' + splittedTime[1])

            const event: CalendarEvent = {
                title: "Frühjahrsputz " + formData.locationName,
                location: formData.adress + " " + "66787 " + Capitalize(formData.city),
                description: "Frühjahrsputz in und um Wadgassen.",
                start,
                end
            };

            const iOSCalendar = ics(event);
            const googleCalendar = google(event);
            const outlookCalendar = outlook(event);

            const requestData = {
                ...formData,
                iOSCalendar: iOSCalendar,
                googleCalendar: googleCalendar,
                outlookCalendar: outlookCalendar
            }

            props.setData({
                ...requestData
            })

            const response = await fetch(`/.netlify/functions/save-form`, {
                body: JSON.stringify(requestData),
                method: 'POST'
            });

            setIsLoading(false)
            props.nextStep && props.nextStep()
        }
    }

    return (
        <div className={isLoading ? "animate-pulse" : ""}>
            <div>
                <p className="font-semibold">{data.locationName}</p>
                <p className="first-letter-uppercase">{data.city}, {data.adress}</p>
                <p className="pt-2 text-sm">
                    <span className="font-semibold">{formData && new Date(formData.date!).toLocaleDateString("de")}</span>
                    <br /> {formData && formData.time && timeRange[formData.time]} Uhr
                     </p>
            </div>
            <div className="mt-8">
                <form>
                    <div className="mb-3">
                        <input className="border-2 border-grey w-full p-2"
                            type="text"
                            placeholder="Name *"
                            value={formData?.name ? formData.name : ""}
                            onChange={(event) => setFormData({ ...formData!, name: event.target.value })} />
                    </div>
                    <div className="mb-3">
                        <input className="border-2 border-grey w-full p-2"
                            placeholder="Email *"
                            type="email"
                            name="email"
                            id="email"
                            value={formData?.email ? formData.email : ""}
                            onChange={(event) => setFormData({ ...formData!, email: event.target.value })} />
                    </div>
                    <div className="mb-8">
                        <input className="border-2 border-grey w-full p-2"
                            type="text"
                            placeholder="Verein"
                            name="club"
                            id="club"
                            value={formData?.club ? formData.club : ""}
                            onChange={(event) => setFormData({ ...formData!, club: event.target.value })} />
                    </div>
                    <div className="font-bold">Bitte darauf achten mit nicht mehr als 2 Personen zu sammeln und den Mindestabstand zu wahren.</div>
                    <p className="text-xs font-semibold my-4">
                        Wir verwenden deine Daten um Mülllsäcke bereitzustellen und die Aktion zu kooridinieren sowie dich über
                        Änderungen zu informieren.

                        Nach der Aktion werden deine Daten gelöscht.
                    </p>
                    <div>
                        <label className="text-xs inline-flex items-center">
                            <input className="mr-3"
                                type="checkbox"
                                checked={!!formData?.dataPrivacy}
                                onChange={() => formData && setFormData({ ...formData, dataPrivacy: !formData.dataPrivacy })} />
                                Ich stimme der Datenverarbeitung zu.</label>
                    </div>
                </form>
            </div>
            <div className="flex flex-row justify-between mt-12">
                <button className="content-end p-2 bg-black bg-opacity-50 font-extrabold text-white"
                    onClick={props.previousStep}>Zurück</button>
                <button className="disabled:opacity-30 p-2 bg-orange-900 font-extrabold text-white"
                    disabled={nextStepDisabled}
                    onClick={() => nextStep()}>Registrieren</button>
            </div>
        </div>
    )
}

export default SetUserData