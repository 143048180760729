
import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";

import { StoryblokBase, StoryblokComponentProps } from "../../@types/storyblok";
import { FreetimePageProps } from "./FreetimePage"
import BackHeader from "../BackHeader";
import { CategoryType } from "./CategoryCard";
import Translations from '../../translation/translations.json'
import BasicCard from '../BasicCard';

interface FreetimeSummaryProps extends StoryblokBase {
    cards: any;
}

interface FreetimeCardItem extends FreetimePageProps {
    slug: string,
}

const FreetimeSummary = (props: StoryblokComponentProps<FreetimeSummaryProps>) => {
    const data = useStaticQuery(graphql`
    query {
        allStoryblokEntry(filter: {field_component: {eq: "freetimePage"}}) {
            edges {
              node {
                id
                uuid
                name
                full_slug
                field_component
                content
                lang
                slug
              }
            }
        }
    }`)

    const freetimeItems = data.allStoryblokEntry.edges.filter((x: any) => x.node.lang === props.lang);
    const { lang } = props;
    const items: FreetimeCardItem[] = [];

    freetimeItems.forEach((element: any) => {
        let item: FreetimeCardItem = JSON.parse(element.node.content);
        item.slug = element.node.slug;

        items.push(item);
    });

    return (
        <div>
            <BackHeader lang={props.lang} type={CategoryType.FreeTime} />
            <div className="grid grid-flow-row-dense gap-5 m-2">
                {items.map((item) =>
                    <Link key={item._uid} to={item.slug}>
                        <BasicCard key={"card_" + item._uid}
                            image={item.slider[0]}
                            imgPos={item.previewImagePosition}
                            title={item.title}
                            subTitle={item.adress}
                            facebook={item.facebook}
                            instagram={item.instagram}>
                            <div className="grid grid-flow-col p-3 text-xs">
                                {
                                    item.openingHours &&
                                    <div>
                                        <p className="font-extrabold">{Translations.openingHours[lang]}</p>
                                        <p className="whitespace-pre">
                                            {item.openingHours}
                                        </p>
                                    </div>
                                }
                                {
                                    item.entranceFee &&
                                    <div>
                                        <p className="font-extrabold">{Translations.entranceFee[lang]}</p>
                                        <p className="whitespace-pre">
                                            {item.entranceFee}
                                        </p>
                                    </div>
                                }
                            </div>
                        </BasicCard>
                    </Link>)}
            </div>
            <div className="h-6" />
        </div>
    )
}

export default FreetimeSummary