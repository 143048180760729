import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { StoryblokBase, StoryblokComponentProps, StoryblokImage, StoryblokMetaFields } from '../../@types/storyblok';
import RichTextResolver from './RichTextResolver/RichTextResolver';
import BasicCard from '../BasicCard';
import BackHeader from '../BackHeader';
import { CategoryType } from './CategoryCard';
import { Helmet } from 'react-helmet';

export interface News {
  _uid: string,
  title: string,
  text: any,
  slider: StoryblokImage[],
  previewImagePosition: string,
  created_at: Date
}

interface NewsSummaryProps extends StoryblokBase {
  seo: StoryblokMetaFields
}


const NewsSummary = (props: StoryblokComponentProps<NewsSummaryProps>) => {
  const { blok, lang } = props;
  const { seo } = blok;
  const [news, setNews] = useState<News[]>([]);

  const data = useStaticQuery(graphql`
  query {
      allStoryblokEntry(filter: {field_component: {eq: "news"}}) {
          edges {
            node {
              id
              uuid
              name
              full_slug
              field_component
              content
              lang
              slug
              created_at
            }
          }
      }
  }`)

  useEffect(() => {

    const items: News[] = data.allStoryblokEntry.edges.filter((x: any) => x.node.lang === lang).map((item: any) => {
      let newsItem: News;
      newsItem = { ...JSON.parse(item.node.content) };
      newsItem.created_at = item.node.created_at;

      return newsItem;

    });

    setNews(items);

  }, [data])

  const resolver = new RichTextResolver();

  const isBrowser = typeof window !== "undefined"

  return (
    isBrowser &&
    <div>
      <Helmet>
        {seo?.title && <title>{seo.title}</title>}
        {seo?.description && <meta name="description" content={seo.description} />}

        <meta property="og:url" content={window ? window.location.href : ""} />
        <meta property="og:type" content="website" />
        {seo?.title && <meta property="og:title" content={seo.title} />}
        {seo?.description && <meta property="og:description" content={seo.description} />}
      </Helmet>
      <BackHeader lang={props.lang} type={CategoryType.News} />
      <div className="bg-grey grid grid-flow-row gap-2 mt-2">
        <div className="grid grid-flow-row-dense gap-5 m-2">
          {
            news.map((news) =>
              <BasicCard
                key={news._uid}
                title={news.title}
                image={news.slider && news.slider[0]}
                imgPos={news.previewImagePosition}
                date={new Date(news.created_at).toLocaleDateString(lang === "default" ? "de" : lang) + ", " + new Date(news.created_at).toLocaleTimeString(lang === "default" ? "de" : lang)}>
                <div className="p-3 text-sm rich-text" dangerouslySetInnerHTML={{ __html: resolver.render(news.text ? news.text : "") }} />
              </BasicCard>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default NewsSummary