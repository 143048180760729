import React from 'react';
import { Link } from 'gatsby';

interface LinkListProps {
    list: string[],
    className?: string
}

const LinkList = (props: LinkListProps) => {

    const { list } = props;
    return (
        <div className="mt-8">
            <p className="font-semibold">Ortschaft wählen:</p>
            <div className="mt-4 flex flex-col divide-y divide-black divide-opacity-40">
                {
                    list.map((item, index) =>
                        <Link key={item + index}
                            className="p-3 bg-grey text-center first-letter-uppercase"
                            to={item}>
                            {item}
                        </Link>
                    )
                }
            </div>
        </div>
    )
}

export default LinkList