import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { StoryblokBase, StoryblokComponentProps, StoryblokImage, StoryblokMetaFields } from '../../@types/storyblok';
import BackHeader from '../BackHeader';
import { CategoryType } from './CategoryCard';
import Translations from '../../translation/translations.json'
import BasicCard from '../BasicCard';
import ExternalLink from '../ExternalLink';
import Filter from '../Filter';

interface RestaurantPageProps extends StoryblokBase {
    category: string,
    title: string,
    image: StoryblokImage,
    previewImagePosition: string,
    adress: string,
    openingHours: string,
    phone: string,
    routeUrl: string,
    facebook: string,
    instagram: string,
    website: string,
    seo: StoryblokMetaFields,
}

const GetNumbersFromString = (text: string) => {
    const numberPattern = /\d+/g;

    return text.match(numberPattern)?.join('');
}

export interface RestaurantCardItem extends RestaurantPageProps {
    slug: string,
    lang: string
}

export const RestaurantCard: React.FC<{ card: RestaurantCardItem, lang: string }> = (props) => {
    const { card } = props

    return (
        <BasicCard key={card._uid}
            image={card.image}
            imgPos={card.previewImagePosition}
            title={card.title}
            subTitle={card.adress}
            facebook={card.facebook}
            instagram={card.instagram}>
            <div className="grid grid-cols-2 p-3 text-xs">
                {
                    card.openingHours ?
                        <div>
                            <p className="font-extrabold">{Translations.openingHours[props.lang]}</p>
                            <p className="whitespace-pre">
                                {card.openingHours}
                            </p>
                        </div> :
                        <div></div>
                }
                {
                    card.phone &&
                    <div className="grid place-content-end">
                        <p className="font-extrabold">{Translations.phone[props.lang]}</p>
                        <a href={"tel:" + GetNumbersFromString(card.phone)} className="whitespace-normal text-blue-light-900">
                            {card.phone}
                        </a>
                    </div>
                }
            </div>
            <div className="grid grid-flow-col p-3">
                <div>
                    {
                        card.routeUrl &&
                        <ExternalLink url={card.routeUrl}>Route</ExternalLink>
                    }
                </div>
                <div className="text-center">

                </div>
                <div className="text-right">
                    {
                        card.website &&
                        <ExternalLink url={card.website}>Website</ExternalLink>
                    }
                </div>

            </div>
        </BasicCard>
    )
}

const RestaurantSummary = (props: StoryblokComponentProps<StoryblokBase>) => {

    const data = useStaticQuery(graphql`
    query {
        allStoryblokEntry(filter: {field_component: {eq: "restaurantPage"}}) {
            edges {
              node {
                id
                uuid
                name
                full_slug
                field_component
                content
                lang
                slug
              }
            }
        }
    }`)

    const { lang } = props;

    const [currentFilter, setCurrentFilter] = useState('all');
    const [categories, setCategories] = useState(['all'])
    const [items, setItems] = useState<RestaurantCardItem[]>([]);

    useEffect(() => {
        const restaurantItems = data.allStoryblokEntry.edges.filter((x: any) => x.node.lang === props.lang);

        const restaurantCardItems: RestaurantCardItem[] = [];

        restaurantItems.forEach((element: any) => {
            let item: RestaurantCardItem = JSON.parse(element.node.content);
            item.slug = element.node.slug;
            item.lang = lang;
            categories.push(item.category)
            restaurantCardItems.push(item);
        });

        setItems(restaurantCardItems);
        setCategories(Array.from(new Set(categories)).filter(e => e));

    }, [data])

    return (
        <div className="max-w-full overflow-x-hidden">
            <BackHeader lang={props.lang} type={CategoryType.Restaurant} />
            {
                categories &&
                <div className="m-2 max-w-full">
                    <Filter filters={categories} setFilter={setCurrentFilter} lang={lang} />
                </div>
            }
            <div className="grid grid-flow-row-dense gap-5 m-2">
                {
                    items.filter((item) => currentFilter === "all" ? item : item.category === currentFilter).map((item) =>
                        <RestaurantCard key={item._uid} card={item} lang={lang} />
                    )
                }
            </div>

        </div>
    )
}

export default RestaurantSummary;