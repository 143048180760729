import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { StoryblokBase, StoryblokComponentProps } from '../../@types/storyblok';
import { HikePageProps } from './HikePage';
import BackHeader from '../BackHeader';
import { CategoryType } from './CategoryCard';
import Difficulty from '../Difficulty';
import Translations from '../../translation/translations.json';

import Distance from '../../images/icons/distance.svg'
import Chevron from '../../images/icons/chevron.svg'
import Duration from '../../images/icons/duration.svg'
import Audio from '../../images/icons/audio.svg'
import { TransformImage } from '../TransformImage';
import ExternalLink from '../ExternalLink';

interface HikeCardItem extends HikePageProps {
    slug: string
}

const timeConvert = (duration: number) => {
    var hours = (duration / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return [rhours, rminutes];
}

const HikeSummary = (props: StoryblokComponentProps<StoryblokBase>) => {
    const data = useStaticQuery(graphql`
    query {
        allStoryblokEntry(filter: {field_component: {eq: "hikePage"}}) {
            edges {
              node {
                id
                uuid
                name
                full_slug
                field_component
                content
                lang
                slug
              }
            }
        }
    }`)

    const hikeItems = data.allStoryblokEntry.edges.filter((x: any) => x.node.lang === props.lang);
    const { lang } = props;
    const items: HikeCardItem[] = [];

    hikeItems.forEach((element: any) => {
        let item: HikeCardItem = JSON.parse(element.node.content);
        item.slug = element.node.slug;

        items.push(item);
    });

    return (
        <div>
            <BackHeader lang={props.lang} type={CategoryType.Hike} />
            <div className="grid grid-flow-row-dense gap-5 m-2">
                {items.map((item) =>
                    <Link key={item._uid} to={item.slug}>
                        {console.log(item.slider[0].filename)}
                        <div className="bg-white">
                            <p className="px-3 py-2 font-extrabold">{item.title}</p>
                            <div className="grid grid-cols-2 gap-4 px-3 pb-3">
                                {
                                    item.slider.length > 0 &&
                                    <img className="object-cover object-center h-36 w-full" width="auto" height="144" src={TransformImage(item.slider[0].filename, "400x0")} alt={item.slider[0].alt} />
                                }
                                {
                                    <div>
                                        <div className="pl-2 text-xs">
                                            <Difficulty type={item.difficulty} lang={lang} />
                                            <div className="flex items-center pt-5 font-semibold leading-4">
                                                {item.audio ? <React.Fragment><Audio className="mr-2" /> {Translations.audioGuide[lang]}</React.Fragment> :
                                                    <p className="leading-4 whitespace-pre-wrap"> </p>}</div>
                                            <div className="pt-4 grid grid-cols-2 grid-rows-2 gap-2">
                                                {item.distance && <div className="flex items-center font-semibold"><Distance className="mr-2" /> {item.distance} km</div>}
                                                {item.uphill && <div className="flex items-center font-semibold"><Chevron className="mr-2 transform rotate-180" /> {item.uphill} hm</div>}
                                                {item.duration && <div className="flex items-center font-semibold"><Duration className="mr-2" />  {timeConvert(item.duration)[0]}:{timeConvert(item.duration)[1]} h</div>}
                                                {item.downhill && <div className="flex items-center font-semibold"><Chevron className="mr-2" /> {item.downhill} hm</div>}
                                            </div>
                                            <div className="grid grid-flow-col pt-4 text-base">
                                                <div>
                                                    {
                                                        item.routeUrl &&
                                                        <ExternalLink url={item.routeUrl}>Route</ExternalLink>
                                                    }
                                                </div>
                                                <div className="text-center">

                                                </div>
                                                <div className="text-right">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </Link>)}
            </div>
            <div className="h-6" />
        </div>
    )

}

export default HikeSummary