import React from 'react';

interface ExternalLinkProps {
    url: string,
    className?: string,
    children: any
}

const ExternalLink = ({ url, children, className }: ExternalLinkProps) =>
    <a className={className ? className + " " + "font-extrabold text-blue-light-900 uppercase" : "font-extrabold text-blue-light-900 uppercase"} href={url} target="blank" rel="noreferrer">{children}</a>

export default ExternalLink