
import React from 'react';

import { StoryblokComponentProps } from '../../@types/storyblok';
import { EventCard, EventCardItem } from './EventSummary';

const EventPage = (props: StoryblokComponentProps<EventCardItem>) => {

    return (
        <EventCard {...props.blok} />
    )
}

export default EventPage