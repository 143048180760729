import * as React from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image, DotGroup } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { StoryblokImage } from '../../@types/storyblok'
import { TransformImage } from '../TransformImage'

export interface SliderProps {
    slides: StoryblokImage[],
    size?: string
}

const MainSlider = (props: SliderProps) => {
    const { slides } = props;

    return (
        slides ?
            <div>
                <CarouselProvider
                    naturalSlideWidth={350}
                    naturalSlideHeight={214}
                    totalSlides={slides.length}
                    dragEnabled={slides.length > 1}
                    interval={6000}
                    isPlaying={true}
                    className="relative"
                >
                    <Slider aria-label="slider">
                        {slides.map((slide, index) =>
                            <Slide aria-label="slide" key={slide.id.toString()} index={index}>
                                <div className="flex relative">
                                    <Image hasMasterSpinner={true} src={TransformImage(slides[0].filename, props.size ? props.size : "", "filters:quality(80)")} alt={slide.alt} />
                                </div>
                            </Slide>
                        )}
                    </Slider>
                    {slides.length > 1 ?
                        <React.Fragment>
                            <ButtonBack role="button" aria-label="Back" className="absolute inset-y-0 left-0 px-4 text-white text-3xl font-extralight">{'<'}</ButtonBack>
                            <ButtonNext role="button" aria-label="Next" className="absolute inset-y-0 right-0 px-4 text-white text-3xl font-extralight">{'>'}</ButtonNext>
                        </React.Fragment> : ''}

                    {slides.length > 1 ?
                        <div className="absolute bottom-4 left-0 right-0">
                            <DotGroup className="justify-center" />
                        </div> : <React.Fragment />}
                </CarouselProvider>
            </div > : <p> Please add Slides </p>
    )
}

export default MainSlider;