import React, { useState } from 'react';
import classNames from 'classnames';

import Translations from '../translation/translations.json';
import "./Filter.css";

interface Filter {
    filters: string[],
    setFilter: (filter: string) => void,
    lang: string
}

const Filter = (props: Filter) => {
    const { filters, setFilter, lang } = props;
    const [currentFilter, setCurrentFilter] = useState("all");

    const changeFilter = (filter: string) => {
        setCurrentFilter(filter)
        setFilter(filter);
    }

    const basicStyle = "py-1 px-2 mr-3 rounded-full border-black border-opacity-70 opacity-70 text-xs border"

    return (
        <div className="grid max-w-full">
            <div className="my-2 overflow-x-auto flex flex-nowrap filter-scroll">
                {
                    filters.map((item, index) => <button
                        key={"filterItem-" + index}
                        className={currentFilter === item ? classNames(basicStyle, "bg-black text-white") : classNames(basicStyle)}
                        onClick={() => changeFilter(item)}>{Translations[item] ? Translations[item][lang] : "Übersetzung ist nicht eingetragen"}
                    </button>)
                }
            </div>
        </div>

    )
}

export default Filter