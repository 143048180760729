import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import StepWizard from 'react-step-wizard';

import "react-datepicker/dist/react-datepicker.css";
import Close from '../../../../images/icons/close.svg'
import SetDateAndTime from './SetDateAndTime';
import SetUserData from './SetUserData';
import Confirmation from './Confirmation';

export interface CollectionData {
    locationId: string,
    locationName: string,
    city: string,
    adress: string,
    name: string | undefined,
    club: string;
    date: string,
    time: string | null,
    email: string,
    dataPrivacy: boolean,
    iOSCalendar: string,
    googleCalendar: string,
    outlookCalendar: string
}

interface CollectionModalProps {
    isOpen: boolean;
    toggleModal: () => void;
    data: CollectionData;
}

const CollectionModal = (props: CollectionModalProps) => {
    ReactModal.setAppElement('#___gatsby');
    const { isOpen } = props;
    const [modalData, setModalData] = useState<CollectionData | null>(null);

    useEffect(() => {
        setModalData(props.data);
    }, [props.data])

    const setData = (data: CollectionData) => {
        setModalData(data);
    }

    const resetData = () => {
        setModalData(null);
    }

    return (
        modalData &&
        <ReactModal
            isOpen={isOpen}
            onRequestClose={props.toggleModal}
            onAfterClose={resetData}
            shouldCloseOnOverlayClick={true}
            style={{
                content: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0
                }
            }}
        >
            <div className="flex flex-col h-full max-w-screen-sm mx-auto">
                <div className="flex-none">
                    <div className="flex flex-row">
                        <h4 className="flex flex-auto">Registrierung</h4>
                        <button className="flex justify-end" onClick={props.toggleModal}><Close /></button>
                    </div>
                </div>
                <div className="flex-1 relative">
                    <StepWizard className="pt-8 flex flex-col-reverse">
                        <SetDateAndTime data={modalData} setData={setData} cancel={props.toggleModal} />
                        <SetUserData data={modalData} setData={setData} />
                        <Confirmation data={modalData} closeModal={props.toggleModal} />
                    </StepWizard>
                </div>
            </div>
        </ReactModal>
    )
}

export default CollectionModal
