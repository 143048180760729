import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactModal from 'react-modal';
import { google, outlook, ics, CalendarEvent } from "calendar-link";


import { StoryblokBase, StoryblokComponentProps, StoryblokImage } from '../../@types/storyblok';
import BackHeader from '../BackHeader';
import { CategoryType } from './CategoryCard';
import BasicCard from '../BasicCard';
import RichTextResolver from './RichTextResolver/RichTextResolver';
import ExternalLink from '../ExternalLink';
import Translations from '../../translation/translations.json';
import CalendarLink from '../CalendarLink';

import Close from '../../images/icons/close.svg'
import Calendar from '../../images/icons/calendar.svg'
import { ValidateEmail } from '../../helper/utils';

interface EventSummaryProps extends StoryblokBase {
    title: string,
    start: string,
    end: string,
    image: StoryblokImage,
    previewImagePosition: string,
    adress: string,
    facebook: string,
    instagram: string,
    left: string,
    center: string,
    right: string,
    routeUrl: string,
    ticket: string
}

export interface EventCardItem extends EventSummaryProps {
    slug: string,
    lang: string
}

export const EventCard = (props: EventCardItem) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    ReactModal.setAppElement('#___gatsby');
    const resolver = new RichTextResolver();

    const event: CalendarEvent = {
        title: props.title,
        description: props.adress,
        start: new Date(props.start),
        end: new Date(props.end),
    };

    const iOSCalendar = ics(event);
    const googleCalendar = google(event);
    const outlookCalendar = outlook(event)

    // safari crashes if there is a white space between date and time
    const startDate = props.start.replace(/ /g, "T");

    return (
        <BasicCard
            image={props.image}
            imgPos={props.previewImagePosition}
            title={props.title}
            subTitle={new Intl.DateTimeFormat('de').format(new Date(startDate)) + ", " + props.adress}
            facebook={props.facebook}
            instagram={props.instagram}>
            <div className="grid grid-flow-col p-3 text-xs">
                {
                    props.left &&
                    <div className="rich-text" dangerouslySetInnerHTML={{ __html: resolver.render(props.left) }} />
                }
                {
                    props.center &&
                    <div className="rich-text" dangerouslySetInnerHTML={{ __html: resolver.render(props.center) }} />
                }
                {
                    props.right &&
                    <div className="rich-text" dangerouslySetInnerHTML={{ __html: resolver.render(props.right) }} />
                }
            </div>
            <div className="grid grid-flow-col p-3">
                <div>
                    {
                        props.routeUrl &&
                        <ExternalLink url={props.routeUrl}>Route</ExternalLink>
                    }
                </div>
                <div className="text-center">
                    {
                        props.ticket &&
                        <ExternalLink url={props.ticket}>Ticket</ExternalLink>
                    }
                </div>
                <div className="text-right">
                    {
                        event &&
                        <button className="font-extrabold text-blue-light-900 uppercase" onClick={() => setIsModalOpen(true)}>{Translations.calendar[props.lang]}</button>
                    }
                </div>
            </div>
            <ReactModal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                shouldCloseOnOverlayClick={true}
                style={{
                    content: {
                        margin: "0 auto",
                        maxWidth: "320px",
                        maxHeight: "240px"
                    }
                }}
            >
                <div className="grid grid-flow-row h-full">
                    <div className="flex flex-row">
                        <h4 className="flex flex-auto">Zum Kalender hinzufügen</h4>
                        <button className="flex justify-end" onClick={() => setIsModalOpen(false)}><Close /></button>
                    </div>
                    <div className="flex flex-col">
                        <div className="mt-6">
                            <p className="font-semibold">
                                {props.title}
                            </p>
                            <p>
                                {new Date(startDate).toLocaleDateString() + " " + new Date(startDate).toLocaleTimeString()}
                            </p>
                            <p>
                                {props.adress}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap content-end">
                        <div className="mt-4 grid grid-flow-col w-full">
                            {
                                <CalendarLink className="text-left" url={iOSCalendar}>ics</CalendarLink>
                            }
                            {
                                <CalendarLink className="text-center" url={googleCalendar}>google</CalendarLink>
                            }
                            {
                                <CalendarLink className="text-right " url={outlookCalendar}>outlook</CalendarLink>
                            }
                        </div>
                    </div>
                </div>
            </ReactModal>
        </BasicCard>
    )
}

interface AddEventData {
    contactPhonenumber: string,
    contactEmail: string,
    contactName: string,
    description: string,
    date: string,
    eventTitle: string
}

interface AddEventModalProps {
    isOpen: boolean;
    toggleModal: () => void;
}

const AddEventModal = (props: AddEventModalProps) => {
    const { isOpen, toggleModal } = props;

    const [modalData, setModalData] = useState<AddEventData | null>(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSended, setIsSended] = useState(false);
    const [isError, setIsError] = useState(false)


    useEffect(() => {
        if (modalData &&
            ValidateEmail(modalData.contactEmail) &&
            modalData.date && modalData.contactName &&
            modalData.eventTitle &&
            modalData.description) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [modalData])

    const resetData = () => {
        setModalData(null);
        setIsSended(false);
        setIsError(false)
        setIsSubmitDisabled(true)
    }

    const sendData = async () => {
        if (modalData) {
            setIsLoading(true)
            const response = await fetch(`/.netlify/functions/add-event`, {
                body: JSON.stringify(modalData),
                method: 'POST'
            });

            if (response.status === 200) {
                setIsSended(true)
            } else {
                setIsError(true)
            }

            setIsLoading(false)
        }
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            onAfterClose={resetData}
            shouldCloseOnOverlayClick={true}
            style={{
                content: {
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0
                }
            }}
        >
            <div className={isLoading ? "animate-pulse" : ""}>
                <div className="flex flex-col h-full max-w-screen-sm mx-auto">
                    <div className="flex-none">
                        <div className="flex flex-row">
                            <h4 className="flex flex-auto">Event melden</h4>
                            <button className="flex justify-end" onClick={props.toggleModal}><Close /></button>
                        </div>
                    </div>
                    {
                        isError &&
                        <div className="flex-1 relative mt-10 text-center">
                            <h1 className="uppercase">ERROR!</h1>
                            <h4>Ein Fehler ist aufgetreten</h4>
                            <p>Probieren Sie es in ein paar Minuten nocheinmal oder melden Sie das Problem an tourismus@wadgassen.de</p>
                            <button className="p-2 bg-red-900 font-extrabold text-white mt-8" onClick={toggleModal}>Schließen</button>
                        </div>
                    }
                    {
                        !isError && isSended && <div className="flex-1 relative mt-10 text-center">
                            <h1 className="uppercase">Vielen Dank!</h1>
                            <h4>Ihre Anfrage wurde gesendet.</h4>
                            <button className="p-2 bg-red-900 font-extrabold text-white mt-8" onClick={toggleModal}>Schließen</button>
                        </div>
                    }
                    {
                        !isError && !isSended &&
                        <div className="flex-1 relative mt-6">
                            <p className="text-sm mb-6">Sie können uns ihr Event melden. Wir prüfen dies und kontaktieren Sie für Rückfragen oder bezüglich der Bestätigung des Events.
                                <br />
                                <span className="text-xs font-bold">Mit * gekennzeichnete Felder sind Pflichtfelder.</span>
                            </p>

                            <div className="mb-3">
                                <input className="border-2 border-grey w-full p-2"
                                    placeholder="Eventname *"
                                    type="text"
                                    name="eventname"
                                    id="eventname"
                                    value={modalData?.eventTitle ? modalData.eventTitle : ""}
                                    onChange={(event) => setModalData({ ...modalData!, eventTitle: event.target.value })} />
                            </div>
                            <div className="mb-3">
                                <input className="border-2 border-grey w-full p-2"
                                    placeholder="Datum und Uhrzeit des Events *"
                                    type="text"
                                    name="eventdate"
                                    id="eventdate"
                                    value={modalData?.date ? modalData.date : ""}
                                    onChange={(event) => setModalData({ ...modalData!, date: event.target.value })} />
                            </div>
                            <div className="mb-3">
                                <textarea className="border-2 border-grey w-full p-2"
                                    placeholder="Beschreibung des Events*"
                                    name="description"
                                    id="description"
                                    value={modalData?.description ? modalData.description : ""}
                                    onChange={(event) => setModalData({ ...modalData!, description: event.target.value })} />
                            </div>

                            <div className="mb-3 mt-3">
                                <input className="border-2 border-grey w-full p-2"
                                    placeholder="Ihr Name *"
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={modalData?.contactName ? modalData.contactName : ""}
                                    onChange={(event) => setModalData({ ...modalData!, contactName: event.target.value })} />
                            </div>
                            <div className="mb-3">
                                <input className="border-2 border-grey w-full p-2"
                                    placeholder="Email *"
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={modalData?.contactEmail ? modalData.contactEmail : ""}
                                    onChange={(event) => setModalData({ ...modalData!, contactEmail: event.target.value })} />
                            </div>
                            <div className="mb-3">
                                <input className="border-2 border-grey w-full p-2"
                                    placeholder="Telefon"
                                    type="text"
                                    name="telefon"
                                    id="telefon"
                                    value={modalData?.contactPhonenumber ? modalData.contactPhonenumber : ""}
                                    onChange={(event) => setModalData({ ...modalData!, contactPhonenumber: event.target.value })} />
                            </div>
                            <div className="flex justify-center mt-8">
                                <button className="disabled:opacity-30 p-2 bg-red-900 font-extrabold text-white"
                                    disabled={isSubmitDisabled}
                                    onClick={sendData}>Event melden</button>
                            </div>
                        </div>
                    }
                </div>

            </div>

        </ReactModal>
    )
}

const EventSummary = (props: StoryblokComponentProps<StoryblokBase>) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    }

    const data = useStaticQuery(graphql`
    query {
        allStoryblokEntry(filter: {field_component: {eq: "eventPage"}}) {
            edges {
              node {
                id
                uuid
                name
                full_slug
                field_component
                content
                lang
                slug
              }
            }
        }
    }`)

    const eventItems = data.allStoryblokEntry.edges.filter((x: any) => x.node.lang === props.lang);
    const items: EventCardItem[] = [];

    eventItems.forEach((element: any) => {
        let item: EventCardItem = JSON.parse(element.node.content);
        item.slug = element.node.slug;
        items.push(item);
    });

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 3);
    const yesterdayDate = yesterday.toISOString().slice(0, 10);

    const sortedItems = items.filter((x)=> new Date(x.start).toISOString().slice(0,10) > yesterdayDate).sort((a,b) => Date.parse(a.start) - Date.parse(b.start))

    return (
        <div>
            <BackHeader lang={props.lang} type={CategoryType.Event} />
            <div className="grid grid-flow-row-dense gap-5 m-2">
                {
                    sortedItems.map((item) =>
                        <EventCard key={item._uid} {...item} lang={props.lang} />
                    )
                }
                <div>{items.length == 0 &&
                    <p className="m-4">Zur Zeit sind leider keine Veranstaltungen geplant.</p>
                }</div>
            </div>
            <button className="flex justify-center items-center w-full px-4 py-3 bg-red-900 text-white font-extrabold text-sm uppercase hover:opacity-80"
                onClick={toggleModal}>
                <Calendar className="pr-1 mr-2" /> Event melden
            </button>
            <AddEventModal isOpen={modalIsOpen} toggleModal={toggleModal} />
        </div>
    )
}

export default EventSummary;