import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { StoryblokBase, StoryblokComponentProps, StoryblokImage } from '../../../@types/storyblok';
import BackHeader from '../../BackHeader';
import { CategoryType } from '../CategoryCard';

export interface CollectionPoint extends StoryblokBase {
    title: string,
    slider: StoryblokImage[],
    childFriendly: boolean,
    description: any,
    adress: string,
    slug: string,
}

interface CollectionPointSummaryProps extends StoryblokBase {
    title: string,
    list: CollectionPoint[]
}

const CollectionPointSummary = (props: StoryblokComponentProps<CollectionPointSummaryProps>) => {
    const { blok } = props;
    const { title } = blok;
    const [cards, setCards] = useState<CollectionPoint[]>([])

    const data = useStaticQuery(graphql`
    query {
        allStoryblokEntry(filter: {field_component: {eq: "collectionPointPage"}, lang: {eq: "default"}}) {
            edges {
              node {
                id
                uuid
                name
                full_slug
                field_component
                content
                lang
                slug
              }
            }
        }
    }`)

    useEffect(() => {
        if (data) {
            const nodes = data.allStoryblokEntry.edges.filter((edge: any) => edge.node.full_slug.includes(title));
            const cardData = nodes.map((item: any) => {
                let collectionPoint: CollectionPoint = { ...JSON.parse(item.node.content) };
                collectionPoint.slug = item.node.slug;
                return collectionPoint;
            });
            setCards(cardData);
        }
    }, [data]);

    return (
        <div>
            <BackHeader lang={props.lang} type={CategoryType.Action} />
            <div className="bg-grey">
                <h3 className="m-2 first-letter-uppercase">{blok.title}</h3>
                <div className="grid grid-flow-row-dense gap-5 m-2">
                    {cards.map((item) =>
                        <Link key={item._uid} to={item.slug}>
                            <div className="bg-white">
                                <p className="px-3 pt-2 font-extrabold">{item.title}</p>
                                {
                                    item.adress &&
                                    <div className="px-3 flex items-center">
                                        <p className="flex-auto text-xs font-semibold">
                                            {item.adress}
                                        </p>
                                    </div>
                                }
                                <div className="grid grid-flow-col p-3 text-xs">
                                    {
                                        item.childFriendly &&
                                        <div>
                                            <p className="whitespace-pre font-semibold">
                                                {item.childFriendly ? "Kindergeeignet" : "nicht Kindergeeignet"}
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Link>)}
                </div>
            </div>
        </div>
    )
}

export default CollectionPointSummary