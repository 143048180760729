import React from 'react';

interface CalendarLinkProps {
    url: string,
    newWindow?: boolean,
    className?: string,
    download?: boolean
}

const CalendarLink: React.FC<CalendarLinkProps> = (props) => {
    return (
        <a className={"font-extrabold text-blue-light-900 uppercase " + props.className}
            href={props.url}
            target="blank"
            rel="noopener noreferrer"
            download={props.download}
            title={"Frühjahrsputz"}>
            {props.children}
        </a>
    )
}

export default CalendarLink