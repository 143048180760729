import React from 'react';

export const timeRange: { [index: string]: string } = {
    "1": "9:00 - 12:00",
    "2": "12:00 - 15:00",
    "3": "15:00 - 18:00"
}

interface TimeRangePickerProps {
    setSelectedTimeRange: (a: string) => void;
    selectedTimeRange: string | null;
    disabledTimeRanges: string[];
}

const TimeRangePicker = (props: TimeRangePickerProps) => {
    const { setSelectedTimeRange, selectedTimeRange } = props;

    return (
        <div className="mt-8 flex flex-col">
            <h5>Bitte eine Zeitspanne auswählen</h5>
            <div className="divide-y divide-black divide-opacity-40">
                {
                    Object.getOwnPropertyNames(timeRange).map((item) =>
                        <div key={item} className="py-3">
                            <label className={props.disabledTimeRanges.includes(item) ? "inline-flex items-center opacity-30" : "inline-flex items-center "} >
                                <input type="radio"
                                    className="mr-3"
                                    value={item}
                                    checked={item === selectedTimeRange}
                                    onChange={() => setSelectedTimeRange(item)}
                                    disabled={props.disabledTimeRanges.includes(item)} />
                                {timeRange[item]}
                            </label>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default TimeRangePicker