import React, { forwardRef, useEffect, useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import DatePicker from 'react-datepicker';

import { CollectionData } from './CollectionModal';
import { CollectionConfig } from '../CollectionConfig';
import TimeRangePicker from '../../../TimeRangePicker';

interface DatePickerStepProps {
    setStartDate: any;
    startDate: any;
}

const DatePickerStep = (props: DatePickerStepProps) => {
    const { setStartDate, startDate } = props;
    const ref = React.createRef();
    const DatepickerInput = forwardRef(({ ...props }, ref: any) => (
        <input type="text" {...props} readOnly ref={ref} />
    ));

    return (
        <div className="flex flex-col">
            <h5>Bitte einen Tag auswählen</h5>
            <DatePicker
                dateFormat="dd-MM-yyyy"
                className="border-2 p-2 text-center w-full border-grey mt-2 mb-4"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                minDate={new Date(CollectionConfig.start)}
                maxDate={new Date(CollectionConfig.end)}
                placeholderText={"Tag auswählen"}
                customInput={<DatepickerInput ref={ref} />}
            />
        </div>
    )
}

interface SetDateAndTimeProps extends Partial<StepWizardChildProps> {
    data: CollectionData,
    setData: (data: CollectionData) => void;
    cancel: () => void;
}

const SetDateAndTime = (props: SetDateAndTimeProps) => {
    const [nextStepDisabled, setNextStepDisabled] = useState(true);
    const [selectedTimeRange, setSelectedTimeRange] = useState<string | null>(null)
    const [startDate, setStartDate] = useState<Date | undefined | null>(null);
    const [disabledTimeRanges, setDisabledTimeRanges] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (startDate) {
            setSelectedTimeRange(null);
            const getData = async () => {
                setIsLoading(true)
                const data = await fetch(`/.netlify/functions/get-disabled-time-ranges?locationId=${props.data.locationId}&startDate=${startDate.toDateString()}`);
                const disabledTimes: string[] = JSON.parse(await data.text());
                setDisabledTimeRanges(disabledTimes ? disabledTimes : [])
                setIsLoading(false);
            }
            getData();
        }
    }, [startDate])

    useEffect(() => {
        if (startDate && selectedTimeRange) {
            setNextStepDisabled(false)
        }
    }, [selectedTimeRange])


    const nextStep = () => {
        const newData: CollectionData = { ...props.data, date: startDate ? startDate.toDateString() : "", time: selectedTimeRange }

        props.setData(newData)
        props.nextStep && props.nextStep()
    }

    return (
        <div className="flex flex-col">
            <div className="mb-8">
                <p className="font-semibold">{props.data.locationName}</p>
                <p className="first-letter-uppercase">{props.data.city}, {props.data.adress}</p>
            </div>
            <div>
                <DatePickerStep setStartDate={setStartDate} startDate={startDate} />
                <div className={isLoading ? "animate-pulse text-center" : "opacity-0"}>Zeiten werden geladen ... </div>
                <div className={startDate && !isLoading ? "opacity-100" : "opacity-0"}>
                    {
                        <TimeRangePicker disabledTimeRanges={disabledTimeRanges}
                            setSelectedTimeRange={setSelectedTimeRange}
                            selectedTimeRange={selectedTimeRange} />
                    }
                </div>
            </div>
            <div className="flex flex-row justify-between mt-12">
                <button className="content-end p-2 bg-black bg-opacity-50 font-extrabold text-white" onClick={() => props.cancel()}>Abbrechen</button>
                <button className="disabled:opacity-30 p-2 bg-orange-900 font-extrabold text-white" disabled={nextStepDisabled} onClick={() => nextStep()}>Weiter</button>
            </div>
        </div>
    )
}

export default SetDateAndTime