import BuildId from "./BuildId"
import CategoryCard from "./CategoryCard"
import CollectionDataSummary from "./spring-cleaning/RegisteredDataTable"
import CollectionPointPage from "./spring-cleaning/CollectionPointPage"
import CollectionPointSummary from "./spring-cleaning/CollectionPointSummary"
import ComponentNotFound from "./ComponentNotFound"
import EventPage from "./EventPage"
import EventSummary from "./EventSummary"
import FeatureCard from "./FeatureCard"
import FreetimePage from "./FreetimePage"
import FreetimeSummary from "./FreetimeSummary"
import SpringCleaningPage from "./spring-cleaning/SpringCleaningPage"
import HikePage from "./HikePage"
import HikeSummary from "./HikeSummary"
import NewsCard from "./NewsCard"
import NewsSummary from "./NewsSummary"
import Page from "./Page"
import RestaurantPage from "./RestaurantPage"
import RestaurantSummary from "./RestaurantSummary"
import RichText from "./RichText"
import Spacing from "./Spacing"
import CovidTests from "./CovidTests"

const ComponentList: { [key: string]: any } = {
  page: Page,
  featureCard: FeatureCard,
  newsCard: NewsCard,
  categoryCard: CategoryCard,
  spacing: Spacing,
  freetimePage: FreetimePage,
  freetimeSummary: FreetimeSummary,
  hikePage: HikePage,
  hikeSummary: HikeSummary,
  restaurantSummary: RestaurantSummary,
  eventSummary: EventSummary,
  buildId: BuildId,
  richText: RichText,
  eventPage: EventPage,
  restaurantPage: RestaurantPage,
  garbagePage: SpringCleaningPage,
  collectionPointSummary: CollectionPointSummary,
  collectionPointPage: CollectionPointPage,
  newsSummary: NewsSummary,
  collectionDataSummary: CollectionDataSummary,
  covidTests: CovidTests
}

const Components = (type: string) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components