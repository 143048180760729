import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';

import { CollectionData } from './CollectionModal';
import { timeRange } from '../../../TimeRangePicker';

import Nature from '../../../../images/icons/nature.svg';
import CalendarLink from '../../../CalendarLink';

interface ConfirmationProps extends Partial<StepWizardChildProps> {
    data: CollectionData
    closeModal: () => void;
}

const Confirmation = (props: ConfirmationProps) => {
    const { data } = props;

    return (
        <div>
            <div className="grid grid-cols-4 gap-4 mt-8 mb-12">
                <div className="col-start-1 col-end-2">
                    <Nature />
                </div>
                <div className="col-start-2 col-end-5">
                    <h1 className="uppercase">Danke!</h1>
                    <h4 className="font-semibold">Für deine Teilnahme</h4>
                </div>
            </div>

            <div className="flex justify-center text-sm">
                <div>
                    <p className="font-semibold">{data.locationName}</p>
                    <p></p>
                    <p className="first-letter-uppercase">{data.city}, {data.adress}</p>
                    <p className="pt-2">
                        <span className="font-semibold">
                            {new Date(data.date!).toLocaleDateString("de")}
                        </span>
                        <br />
                        {data.time && timeRange[data.time]} Uhr
                </p>
                </div>
            </div>
            <p className="mt-12 font-bold text-center">Zum Kalender hinzufügen:</p>
            <div className="mt-4 grid grid-flow-row gap-4 w-full">
                {
                    <CalendarLink className="text-center" download={true} url={data.iOSCalendar}>ics</CalendarLink>
                }
                {
                    <CalendarLink className="text-center" url={data.googleCalendar}>google</CalendarLink>
                }
                {
                    <CalendarLink className="text-center " url={data.outlookCalendar} >outlook</CalendarLink>
                }
            </div>
            <div className="flex justify-center mt-12">
                <button className="p-2 bg-orange-900 font-extrabold text-white"
                    onClick={props.closeModal}>Schließen</button>
            </div>
        </div>
    )
}

export default Confirmation