import * as React from 'react'
import { Helmet } from "react-helmet";

import { StoryblokBase, StoryblokMetaFields, StoryblokComponentProps } from '../../@types/storyblok';
import Components from './Components';

interface PageBlok extends StoryblokBase {
    body: StoryblokBase[],
    seo?: StoryblokMetaFields,
}

interface StoryblokPageProps extends StoryblokComponentProps<PageBlok> {
    location: any
}

const Page = (props: StoryblokPageProps) => {

    const { seo } = props.blok

    return (
        <React.Fragment>
            <Helmet>
                {seo?.title && <title>{seo.title}</title>}
                {seo?.description && <meta name="description" content={seo.description} />}
            </Helmet>
            {props.blok.body && props.blok.body.map((blok) => React.createElement(Components(blok.component), { key: blok._uid, blok: blok, location: props.location, lang: props.lang }))}
        </React.Fragment>
    )
}

export default Page